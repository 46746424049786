import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts"

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart"
import { getCurrentMonthAndPreviousFiveMonths } from "../functions";
import {getStatsForMonth} from "../../functions";
import { ContentType } from "@/enums";
import { Map } from "@/types";

const chartConfig = {
  rtoPercentage: {
    label: "RTO %",
    color: "#2563eb",
  },
} as ChartConfig

const buildDataArray = (dates: Map<ContentType>) => {
  const sixMonths = getCurrentMonthAndPreviousFiveMonths(new Date());
  return sixMonths.map((month, index )=> {
    return {month: sixMonths[index], rtoPercentage: getStatsForMonth(month, dates).percentageInOffice}
  });
}

export const MonthlyStatChart = ({dates}: {dates: Map<ContentType>}) => {
  const chartData = buildDataArray(dates);
  
  return (
    <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
      <BarChart accessibilityLayer data={chartData}>
      <CartesianGrid vertical={false} />
      <XAxis
      dataKey="month"
      tickLine={false}
      tickMargin={0}
      axisLine={false}
      tickFormatter={(value) => value}
    />
          <YAxis
          dataKey="rtoPercentage"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => `${value}%`}
    />
    <ChartTooltip content={<ChartTooltipContent />} />
        <Bar dataKey="rtoPercentage" fill="var(--color-rtoPercentage)" radius={4} />
      </BarChart>
    </ChartContainer>
  )
}
