import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { signInWithGoogle, signInAnonymously } from "../../firebase";

export const LoginOptionsSection = () => {
  return (<Card
  className="sm:col-span-3" x-chunk="dashboard-05-chunk-0"
>
  <CardHeader className="pb-3">
    <CardTitle>Please login first</CardTitle>
  </CardHeader>
  <CardContent>
  <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-2">
    <div>
  <Button variant="outline" className="w-full" onClick={signInWithGoogle}>
            Login with Google
          </Button></div>
         <div> <Button variant="outline" className="w-full" onClick={signInAnonymously}>
            Login with Anonymous mode
          </Button>
          </div>
          </div>
  </CardContent>
</Card>)
}