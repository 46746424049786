import { ContentType } from "@/enums";
import { Map } from "@/types";
import { differenceInBusinessDays, isWeekend, isWithinInterval } from 'date-fns';

export const getCurrentMonthAndPreviousFiveMonths = (inputDate: Date) => {
    const months: string[] = [];
    for (let i = 0; i < 4; i++) {
        const currentDate = new Date(inputDate);
        currentDate.setMonth(currentDate.getMonth() - i);
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1)
        const formattedDate = `${year}-${month}`;
        months.push(formattedDate);
    }

    return months;
}

export const getQuarterMonths = (date = new Date()) => {
  const currentDate = new Date(date);
  const month = (currentDate.getMonth() + 1);
  const year = currentDate.getFullYear();

  switch (month) {
    case 1:
      return [`${year}-11`, `${year}-12`, `${year+1}-1`];
    case 2: 
    case 3:
    case 4:
      return [`${year}-1`, `${year}-2`, `${year}-3`];
    case 5: 
    case 6:
    case 7:
      return [`${year}-5`, `${year}-6`, `${year}-7`];
    case 8: 
    case 9:
    case 10:
      return [`${year}-8`, `${year}-9`, `${year}-10`];
    case 11: 
    case 12:
      return [`${year}-11`, `${year}-12`, `${year+1}-1`];
    default:
      return [];
  }
}

export const getNumberOfWorkDays = (startDate: Date, endDate: Date, dates: Map<ContentType>) => {
  let datesKey = Object.keys(dates);
  let weekdays = differenceInBusinessDays(endDate, startDate);
  weekdays = isWeekend(endDate) ?  weekdays : weekdays + 1;

  datesKey.forEach((date) => {
    if (isWithinInterval(new Date(date), { start: startDate, end: endDate }) && dates[date] === ContentType.Exclude) {
      weekdays--;
    }
  });
  return weekdays;
};

export const getNumberOfInOfficeDays = (startDate: Date, endDate: Date, dates: Map<ContentType>) => {
  let datesKey = Object.keys(dates);
  let matchDatesCount = 0;
  datesKey.forEach((date) => {
    if (isWithinInterval(new Date(date.replace(/-/g, '/')), { start: startDate, end: endDate }) && dates[date] === ContentType.InOffice) {
      matchDatesCount++;
    }
  });
  return matchDatesCount;
};
