import styled from "styled-components";

export const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */

  /* https://dribbble.com/shots/7239982-Calendar-App-design/attachments/217275?mode=media */
  width: 100%;
  min-height: 180px;
  padding: 10px 0 0px 0px;

  .react-calendar {
    width: 100%;
    background: none;
    border: none;
  }

  .react-calendar__month-view__days__day abbr {
    display: none;
  }

  .react-calendar__month-view__days__day .day {
    display: block;
    /* padding: 10px; */
    /* height: 40px; */
    width: 30px;
    padding: 10px 0px;
    color: #000;
    font-weight: normal;
  }

  .react-calendar__month-view__days__day .InOffice {
    position: relative;

    &:after {
      content: "RTO";
      position: absolute;
      color: #f97173;
      display: block;
      margin-left: 4px;
      margin-top: 2px;
      font-size: 10px;
    }
  
  }

  .react-calendar__month-view__days__day .PlanToComeInOffice {
    border-bottom: 5px solid rgb(249, 113, 115, 0.7);
  }

  .react-calendar__month-view__days__day .Exclude {
    color: #ccc;
  }

  .react-calendar__month-view__days__day {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: none;
  }

  .react-calendar__tile:enabled:hover .day {
    
  }

  .react-calendar__tile--active,
  .react-calendar__tile--now {
    background: none;
  }

  .react-calendar__tile--now .day {
    color: red;
  }

  .react-calendar__navigation button:disabled {
    background: none;
  }

  .react-calendar__tile:disabled {
    background-color: #fff;
  }

  .react-calendar__month-view__days > .react-calendar__tile,
  .react-calendar__month-view__weekdays__weekday {
    flex-basis: 20% !important;
    max-width: 20% !important;
  }

  .react-calendar__month-view__weekdays__weekday:nth-child(6),
  .react-calendar__month-view__weekdays__weekday:nth-child(7) {
    display: none !important;
  }

  .react-calendar__month-view__days__day--weekend {
    display: none !important;
  }

  /* ~~~ navigation styles ~~~ */
  .react-calendar__navigation {
    display: flex;

    .react-calendar__navigation__label {
      font-weight: bold;
    }

    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
    }

    button:enabled:hover,
    button:enabled:focus {
      background-color: #fff;
    }
  }
`;

export const StatisticsContainer = styled.div`
  background: #fff;
  border-radius: 25px;
  min-height: 100px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 50px;
  padding: 20px 10px;
  margin-top: -20px;
`;
