import "../index.css";
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Copy,
  PanelLeft,
} from "lucide-react"
import { LoginBox } from "./LoginBox";
import { useCallback, useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import firebase, { database, signOut } from "../firebase";
import { LogoutBox } from "./LogoutBox";
import { MonthlyStatChart } from "./charts/MonthlyStatChart";
import { LoginOptionsSection } from "./LoginOptionsSection/LoginOptionsSection";
import { StatsSection } from "./StatsSection/StatsSection";
import {CalendarApp} from "../CalendarNew"
import {
  CalendarCheck,
} from "lucide-react"

export default function App() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [dates, setDates] = useState({});

  const uid = user?.uid;



  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  const fetchPost = useCallback(async (uid: string) => {
    const docRef = collection(database, uid);
    const unsub = onSnapshot(docRef, (doc) => {
      let newObj = {};
      doc.forEach((doc) => {
        newObj = { ...doc.data(), ...newObj };
      });
      setDates(newObj);
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (uid) {
      fetchPost(uid);
    }
  }, [uid, fetchPost]);

  const handleLogout = () => {
    signOut();
    setDates({});
  };

  return (
    <div className="flex min-h-screen w-full flex-col">
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
    <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
     <CalendarCheck className="h-4.5 w-4.5 inline" /> <span className="font-bold text-lg">RTO Tracker</span>
      </div>
      {user && <LogoutBox user={user} onLogout={handleLogout}/>}
      </header>
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
      {!user && <LoginOptionsSection />}
      {user && <StatsSection dates={dates} />}
      <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-2">

        <Card
            className="xl:col-span-1" x-chunk="dashboard-01-chunk-4"
          >
            <CardContent>
            <CalendarApp dates={dates} user={user} />
              </CardContent>
              </Card>

              <Card className="xl:col-span-1 overflow-hidden" x-chunk="dashboard-01-chunk-5">
            <CardHeader>
              <CardTitle>Stats for last 4 months</CardTitle>
            </CardHeader>
            <CardContent>
              <MonthlyStatChart dates={dates}/>
              </CardContent>
              </Card>
        </div>
      </main>
      </div>
  );
}
