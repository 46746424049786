import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "@/components/ui/table"

export const StatsTable = ({
    stats, average
}: {
    stats: {
        numberOfWorkDays: number;
        numberOfInOfficeDays: number;
        percentageInOffice: string;
        month: string;
    }[],
    average: string
}) => {
    return (
        <Table>
  <TableCaption>Overall: {average}%</TableCaption>
  <TableHeader>
    <TableRow>
      <TableHead className="w-[100px]">Month</TableHead>
      <TableHead>RTO days</TableHead>
      <TableHead>Work days</TableHead>
      <TableHead className="text-right">RTO %</TableHead>
    </TableRow>
  </TableHeader>
  <TableBody>
  {stats.map(stat => (
    <TableRow>
      <TableCell className="font-medium">{stat.month}</TableCell>
      <TableCell>{stat.numberOfInOfficeDays}</TableCell>
      <TableCell>{stat.numberOfWorkDays}</TableCell>
      <TableCell className="text-right">{stat.percentageInOffice}</TableCell>
    </TableRow>
    ))}
  </TableBody>
</Table>
    );
}