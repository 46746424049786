import { ContentType } from "./enums";
import { MonthlyStat, Map } from "./types";



const WorkDayMap: Map<number> = {
  "2023-5": 23,
  "2023-6": 22,
  "2023-7": 21,
  "2023-8": 23,
  "2023-9": 21,
  "2023-10": 22,
  "2023-11": 22,
  "2023-12": 21,
  "2024-1": 23,
  "2024-2": 21,
  "2024-3": 21,
  "2024-4": 22,
  "2024-5": 23,
  "2024-6": 20,
  "2024-7": 23,
  "2024-8": 22,
  "2024-9": 21,
  "2024-10": 23,
};

export const getNumberOfWorkDays = (monthString: string, dates: Map<ContentType>) => {
  let datesKey = Object.keys(dates);
  let matchDatesCount = WorkDayMap[monthString];
  datesKey.forEach((date) => {
    if (date.includes(monthString) && dates[date] === ContentType.Exclude) {
      matchDatesCount--;
    }
  });
  return matchDatesCount;
};

export const getNumberOfInOfficeDays = (monthString: string, dates: Map<ContentType>) => {
  let datesKey = Object.keys(dates);
  let matchDatesCount = 0;
  datesKey.forEach((date) => {
    if (date.includes(monthString) && dates[date] === ContentType.InOffice) {
      matchDatesCount++;
    }
  });
  return matchDatesCount;
};

export const getStatsForMonth = (month: string, dates: Map<ContentType>): MonthlyStat => {
  const numberOfWorkDays = getNumberOfWorkDays(month, dates);
  const numberOfInOfficeDays = getNumberOfInOfficeDays(month, dates);

  return {
    numberOfWorkDays,
    numberOfInOfficeDays,
    percentageInOffice: (
      (numberOfInOfficeDays / numberOfWorkDays) *
      100
    ).toFixed(2),
  };
};

export const hasAugToOct2023Entries = (dates: object) =>
  Object.keys(dates).some(
    (key) =>
      key.includes("2023-8") ||
      key.includes("2023-9") ||
      key.includes("2023-10"),
  );
